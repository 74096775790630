import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import BgImg from "gatsby-background-image"
import * as styles from "./hero.module.scss"
const Hero = ({ banner, phone, customCTA }) => {
  const { mobile, desktop, heading, subheading, buttons, tagline } = banner
  const { button_text, linked_page, anchor_reference } =
    buttons.value[0].elements
  const sources = [
    mobile.value[0].fluid,
    {
      ...desktop.value[0].fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <BgImg
      fluid={sources}
      alt={desktop.value[0].description}
      className={styles.hero}
      preserveStackingContext={true}
    >
      <section className={`container ${styles.bannerContent}`}>
        <h1>{heading.value}</h1>
        <RichTextElement value={subheading.value} className />
        <div className={styles.homePageBannerLinks}>
          {linked_page && (
            <Link
              to={`/${linked_page?.value[0]?.elements?.slug.value}`}
              className="primary-button"
              id="hero-cta"
            >
              {button_text.value}
            </Link>
          )}

          {anchor_reference && (
            <AnchorLink
              to={`#${anchor_reference?.value[0]?.name}`}
              className="primary-button"
              id="hero-cta"
            >
              {button_text.value}
            </AnchorLink>
          )}

          <div className={styles.callText}>
            {tagline.value ? (
              <p className={styles.smallText}>{tagline.value}</p>
            ) : (
              <p className={styles.smallText}>{customCTA}</p>
            )}
            <a
              href={`tel:${phone}`}
              className={styles.phone}
              id="hero-tracking-phone"
            >
              {phone}
            </a>
          </div>
        </div>
      </section>
    </BgImg>
  )
}

export default Hero
