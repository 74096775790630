// extracted by mini-css-extract-plugin
export var buyNow = "secondary-header-module--buy-now--EVmch";
export var phone = "secondary-header-module--phone--O-mzU";
export var phoneLink = "secondary-header-module--phone-link--sFybP";
export var portalLink = "secondary-header-module--portal-link--vbBsc";
export var portalLinkWrapper = "secondary-header-module--portal-link-wrapper--APl8b";
export var portalLinks = "secondary-header-module--portal-links--U0eFq";
export var prLogo = "secondary-header-module--pr-logo--EAUmb";
export var reviewsIcon = "secondary-header-module--reviews-icon--870Bi";
export var secondaryHeader = "secondary-header-module--secondary-header--GfUza";
export var secondaryHeaderInner = "secondary-header-module--secondary-header-inner--zIMWs";
export var smallText = "secondary-header-module--small-text--HZHuo";