import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { AppContext } from "../context/context"
import * as styles from "./secondary-header.module.scss"

const SecondaryHeader = ({
  phone,
  showReviews,
  showPortal,
  showBuyOnline,
  buyOnlineLink,
  pestroutesLogo,
  reviewsLogo,
  customCTA,
  navItems,
  location,
}) => {
  const { size } = React.useContext(AppContext)

  if (size < 600) {
    return (
      <section className={styles.secondaryHeader}>
        <a href={`tel: ${phone}`} className={styles.secondaryHeaderInner}>
          <span id="tracking-number" className={styles.phoneLink}>
            {phone}
          </span>
        </a>
        {showBuyOnline.codename === "no" ? (
          <Link
            to="/contact-us"
            className={styles.buyNow}
            id="secondary-header-cta"
          >
            Get Started
          </Link>
        ) : (
          <AnchorLink
            to={`/${buyOnlineLink}#buy-online`}
            className={`${styles.buyNow} secondary-header-cta`}
            stripHash={true}
            id="secondary-header-cta"
          >
            Buy Now
          </AnchorLink>
        )}
      </section>
    )
  } else if (size < 768) {
    return (
      <section className={styles.secondaryHeader}>
        <div className={styles.secondaryHeaderInner}>
          <p className={styles.smallText}>{customCTA}</p>
          <a href={`tel: ${phone}`} className={styles.secondaryHeaderInner}>
            <span id="tracking-number" className={styles.phoneLink}>
              {phone}
            </span>
          </a>
          {showBuyOnline.codename === "no" ? (
            <Link
              to="/contact-us"
              className={styles.buyNow}
              id="secondary-header-cta"
            >
              Get Started
            </Link>
          ) : (
            <AnchorLink
              to={`/${buyOnlineLink}#buy-online`}
              className={`${styles.buyNow} secondary-header-cta`}
              stripHash={true}
              id="secondary-header-cta"
            >
              Buy Now
            </AnchorLink>
          )}
        </div>
      </section>
    )
  } else {
    const prLogoPath = pestroutesLogo.nodes[0].childImageSharp.fixed
    const reviewLogoPath = reviewsLogo.nodes[0].childImageSharp.fixed
    return (
      <section className={styles.secondaryHeader}>
        <div className={styles.portalLinks}>
          {showPortal.codename !== "no" && (
            <span className={styles.portalLinkWrapper}>
              <Img
                fixed={prLogoPath}
                alt="Pestroutes Logo"
                className={styles.prLogo}
              />
              <a href="/login" className={styles.portalLink}>
                Client Login
              </a>
            </span>
          )}

          {showPortal.codename !== "no" && showReviews.codename !== "no" && (
            <span>|</span>
          )}
          {showReviews.codename !== "no" && (
            <span className={styles.portalLinkWrapper}>
              <Img
                fixed={reviewLogoPath}
                alt="Reviews Icon"
                className={styles.reviewsIcon}
              />
              <Link to="/reviews" className={styles.portalLink}>
                Customer Reviews
              </Link>
            </span>
          )}
          {navItems.value && showReviews.codename !== "no" && <span>|</span>}
          {navItems.value &&
            navItems.value.map((navItem, i) => {
              return (
                <>
                  <span className={styles.portalLinkWrapper}>
                    <Link
                      to={
                        navItem.elements.external_link.value === ""
                          ? `/${navItem.elements.page.value[0].elements.slug.value}`
                          : `/${navItem.elements.external_link.value}`
                      }
                      className={styles.portalLink}
                    >
                      {navItem.elements.link_text.value}
                    </Link>
                  </span>
                  {navItems.value.length > 1 &&
                    i + 1 !== navItems.value.length && <span>|</span>}
                </>
              )
            })}
        </div>
        <div className={styles.secondaryHeaderInner}>
          {size > 1024 && <p className={styles.smallText}>{customCTA}</p>}
          <a href={`tel: ${phone}`} className={styles.phoneLink}>
            <span id="tracking-number">{phone}</span>
          </a>
          {showBuyOnline.codename === "no" ? (
            <Link
              to="/contact-us"
              className={styles.buyNow}
              id="secondary-header-cta"
            >
              Get Started
            </Link>
          ) : (
            <AnchorLink
              to={`/${buyOnlineLink}#buy-online`}
              className={`${styles.buyNow} secondary-header-cta`}
              stripHash={true}
            >
              Buy Now
            </AnchorLink>
          )}
        </div>
      </section>
    )
  }
}

SecondaryHeader.propTypes = {
  phone: PropTypes.string,
}

SecondaryHeader.defaultProps = {
  phone: "(888) 888-8888",
}

export default SecondaryHeader
